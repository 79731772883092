import axios from "axios";
import moment from "moment";
import { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
  Divider,
  Row,
  Col,
  Switch,
} from "antd";
import {
  EditFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  clearTicketList,
  fetchTickets,
  ticketList,
} from "../../pages/Tickets/ticketsSlice";
import { fetchActs } from "../../pages/Acts/actsSlice";
import { apiGetAct, apiCreateAct, apiEditAct } from "../../api/acts";
import TicketEditor from "./TicketEditor";
import Ticket from "./Ticket";
import ImgUpload from "./ImgUpload";
import "./createModal.less";

const host_url = process.env.REACT_APP_HOST_URL;

const { Text, Title } = Typography;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const CreateModal = (props) => {
  const {
    editMode,
    isModalVisible,
    setIsModalVisible,
    handleShowModal,
    setEditMode,
  } = props;
  const [ticketModal, setTicketModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actId, setActId] = useState(null);
  const [actEdit, setActEdit] = useState(false);
  const [ticketEdit, setTicketEdit] = useState({ status: false, tkData: {} });
  const [form] = Form.useForm();
  const [activeText, setActiveText] = useState(false);
  const [tkActiveText, setTkActiveText] = useState(false);
  const [tenant] = useState(() => JSON.parse(localStorage.getItem("tenant")));
  const [fileList, setFileList] = useState([]);
  const [rawActData, setRawActData] = useState({});

  const dispatch = useDispatch();
  const tickets = useSelector(ticketList);

  const getEditAct = async (id) => {
    try {
      let res = await apiGetAct(id, tenant.key);
      console.log("getEditAct", res);
      let data = res.data;
      if (data.start_at != null && data.end_at != null) {
        data.actDateRange = [
          moment(data.start_at).add(8, "h"),
          moment(data.end_at).add(8, "h"),
        ];
      }

      console.log({ data });
      setRawActData(data);
      form.setFieldsValue(data);
      if (data.image_main != null) {
        await setFileList([
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: data.image_main,
          },
        ]);
      }

      await setActiveText(data.is_active);
    } catch (err) {
      console.log(err);
      Modal.info({
        title: "發生錯誤",
        icon: <ExclamationCircleOutlined />,
        content: `${err.message} 發生錯誤，請稍候再試`,
        okText: "ok",
      });
    }
  };

  useEffect(() => {
    //editMode:{status:false,id:null}
    const initModal = async () => {
      await setActId(editMode.id);
      if (editMode.status) {
        await getEditAct(editMode.id);
        await dispatch(fetchTickets(editMode.id));
      }
      // await console.log(form.getFieldValue(['is_active']));
    };
    initModal();
  }, [editMode]);

  const handleActEdit = (data) => {
    setActEdit(true);
  };
  const handleActActive = (val) => {
    setActiveText(val);
  };
  const handleTkActive = (val) => {
    setTkActiveText(val);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
    dispatch(clearTicketList());
    dispatch(fetchActs());
    setFileList([]);
  };

  const handleTicketSet = (edit) => {
    if (!actId) return;
    if (edit.id !== undefined) setTicketEdit({ status: true, tkData: edit });
    else setTicketEdit({ status: false, tkData: {} });
    setTicketModal(true);
  };

  //-- Form--
  const handleUploadImg = async (newId) => {
    let id = newId || actId;
    const formData = new FormData();
    console.log({ fileList }, fileList[0]);
    if (fileList[0]) {
      formData.append("image_main", fileList[0].originFileObj);
    } else {
      formData.append("image_main", "");
    }

    try {
      let res = await apiEditAct(id, tenant.key, formData);
      console.log("UploadImg", res);
      setRawActData({ ...rawActData, image_main: res.data.image_main });
      setActEdit(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      Modal.info({
        title: "發生錯誤",
        icon: <ExclamationCircleOutlined />,
        content: `${err.message} 圖片上傳失敗`,
        okText: "ok",
      });
    }
  };
  const nextStep = () => {
    form.submit();
  };
  const onFinish = async (values, text) => {
    // await handleUploadImg();
    setLoading(true);
    console.log("Success:", values);
    const { title, desc, actDateRange, is_active } = values;
    let payload = {
      title,
      desc,
      is_active,
      start_at: actDateRange[0]._d,
      end_at: actDateRange[1]._d,
    };
    console.log({ payload });
    console.log({ editMode });
    if (editMode.status) {
      try {
        let res = await apiEditAct(actId, tenant.key, payload);
        console.log("edited", res);
        setActEdit(false);
        setLoading(false);
        if (fileList[0] && fileList[0].uid == -1) return;
        await handleUploadImg();
      } catch (err) {
        console.log(err);
        setLoading(false);
        Modal.info({
          title: "發生錯誤",
          icon: <ExclamationCircleOutlined />,
          content: `${err.message} 活動編輯失敗`,
          okText: "ok",
        });
      }
    } else {
      try {
        console.log({ fileList });
        let res = await apiCreateAct(tenant.key, payload);
        console.log(res);
        if (res.status == 201) {
          await setActId(res.data.id);
          setEditMode({ status: true, id: res.data.id });

          if (fileList[0] && fileList[0].uid != -1) {
            await handleUploadImg(res.data.id);
          }
        }
        await setLoading(false);
      } catch (err) {
        console.log(err);
        await setLoading(false);
        Modal.info({
          title: "發生錯誤",
          icon: <ExclamationCircleOutlined />,
          content: `${err.message} 活動建立失敗`,
          okText: "ok",
        });
      }
    }
    // if (fileList[0] && fileList[0].uid != -1) {
    //   await handleUploadImg();
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onChange = (value, dateString) => {
    console.log("Selected Time: ", value);
    console.log("Formatted Selected Time: ", dateString);
  };

  const saveAndClose = async () => {
    console.log({ rawActData });
    console.log(form.getFieldsValue());
    const submitData = await form.getFieldsValue();
    const keyList = Object.keys(submitData);
    let pass2OnFinished = false;
    keyList.forEach((el) => {
      if (JSON.stringify(rawActData[el]) !== JSON.stringify(submitData[el])) {
        pass2OnFinished = true;
      }
    });
    if (rawActData.image_main) {
      if (fileList.length == 0) {
        pass2OnFinished = true;
      } else if (rawActData.image_main != fileList[0].url) {
        pass2OnFinished = true;
      }
    } else if (fileList.length != 0) {
      pass2OnFinished = true;
    }

    console.log({ pass2OnFinished });
    console.log({ fileList });
    console.log("editMode.status", editMode.status);
    if (pass2OnFinished) {
      await onFinish(submitData);
    }
    handleCancel();
  };

  // TODO: contract_addr: 0xFf9D03699EFa9A5599e92fEf9643223f71E63662
  return (
    <>
      <Button
        style={{ marginBottom: "16px" }}
        type="primary"
        size="large"
        icon={<PlusOutlined />}
        onClick={handleShowModal}
      >
        建立活動
      </Button>

      <Modal
        title={
          <Row justify="space-between">
            <Title level={4} style={{ marginBottom: "0" }}>
              {editMode.status ? "編輯活動" : "新增活動"}
            </Title>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={handleCancel}
            ></Button>
          </Row>
        }
        visible={isModalVisible}
        width={1200}
        onCancel={handleCancel}
        closable={false}
        forceRender
        maskClosable={false}
        // footer={[
        //   <Button key="back" onClick={handleCancel}>
        //     關閉
        //   </Button>,
        // ]}
        footer={null}
      >
        <Form
          name="basic"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          colon={false}
          style={{ maxWidth: "550px" }}
          form={form}
        >
          <Title level={5}>
            Step1 : 建立活動資訊
            {/* {actId && (
              <Button
                onClick={handleActEdit}
                type="text"
                style={{ color: "#777" }}
                icon={<EditFilled />}
              ></Button>
            )} */}
          </Title>
          <Form.Item
            label="活動名稱"
            name="title"
            rules={[
              { required: true, message: "Please input your activity title!" },
            ]}
          >
            {/* <Input readOnly={actId && !actEdit && true} /> */}
            <Input />
          </Form.Item>

          <Form.Item
            label="活動說明"
            name="desc"
            rules={[
              {
                required: true,
                message: "Please input your activity description!",
              },
            ]}
          >
            {/* <TextArea rows={4} readOnly={actId && !actEdit && true} /> */}
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item
            label="活動期間"
            name="actDateRange"
            rules={[
              { required: true, message: "Please input your date range!" },
            ]}
          >
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              onChange={onChange}
              // disabled={actId && !actEdit && true}
            />
          </Form.Item>
          <Form.Item label="活動圖片">
            <ImgUpload
              actEdit={actEdit}
              fileList={fileList}
              setFileList={setFileList}
            />
          </Form.Item>
          <Form.Item label="活動狀態">
            <Space align="baseline">
              <Form.Item name="is_active" valuePropName="checked">
                <Switch
                  // disabled={actId && !actEdit && true}
                  onChange={handleActActive}
                />
              </Form.Item>
              <Text
                type="secondary"
                className="caption"
                style={{ marginLeft: "4px" }}
              >
                {activeText ? "開啟" : "關閉"}
              </Text>
            </Space>
          </Form.Item>
        </Form>
        <div
          // wrapperCol={{
          //   offset: 5,
          // }}
          className="text-right"
        >
          {/* <Space> */}
          {!actId && (
            <Button loading={loading} type="primary" onClick={nextStep}>
              下一步
            </Button>
          )}
          {actId && actEdit && (
            <Space>
              <Button
                disabled={!actEdit && true}
                onClick={() => setActEdit(false)}
              >
                取消
              </Button>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                disabled={!actEdit && true}
              >
                儲存
              </Button>
            </Space>
          )}
          {/* </Space> */}
        </div>
        {actId && (
          <>
            <Divider></Divider>
            <Title level={5}>Step 2 :活動票券</Title>
            <Row gutter={16}>
              {tickets.map((el) => (
                <Col span={8} key={el.key}>
                  <Ticket tkData={el} handleTicketSet={handleTicketSet} />
                </Col>
              ))}
              <Col span={8} style={{ padding: "0 16px 16px 8px" }}>
                <div
                  className={"ticketAdder"}
                  disabled={!actId}
                  onClick={handleTicketSet}
                >
                  {/* <div className={'ticketAdder'} onClick={handleTicketSet}> */}
                  <PlusOutlined className="addIcon" />
                </div>
              </Col>
            </Row>
            <Divider></Divider>
            <div className="text-right">
              <Button loading={loading} type="primary" onClick={saveAndClose}>
                完成並關閉
              </Button>
            </div>
          </>
        )}

        <TicketEditor
          actId={actId}
          ticketModal={ticketModal}
          setTicketModal={setTicketModal}
          ticketEdit={ticketEdit}
          setTicketEdit={setTicketEdit}
          tkActiveText={tkActiveText}
          handleTkActive={handleTkActive}
        />
      </Modal>
    </>
  );
};

export default CreateModal;
