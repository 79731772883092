import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../components/counter/counterSlice';
import sidebarReducer from '../components/sidebar/sidebarSlice';
import breadcrumbReducer from '../components/breadcrumbBar/breadcrumbBarSlice';
import actsReducer from '../pages/Acts/actsSlice';
import authReducer from '../pages/Login/auth/authSlice'
import ticketsReducer from '../pages/Tickets/ticketsSlice'
import usersReducer from '../pages/Users/uersSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    counter: counterReducer,
    sidebar: sidebarReducer,
    breadcrumbBar: breadcrumbReducer,
    activities: actsReducer,
    tickets: ticketsReducer,
    users: usersReducer
  },
});
