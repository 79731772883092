import { Button, Typography, Form, Input, Space } from "antd";
import { Outlet } from "react-router-dom";
import "./loginSocial.less";

const { Title, Text, Link } = Typography;
const Entrance = () => {
  return (
    <div className=" loginSocial verticalWrapper verticalCenter">
      <div className="loginTitle">
        <img src="/prism2.png" alt="" />
        <div>
          <Title
            level={2}
            className="titleFont1"
            style={{ marginBottom: "0px" }}
          >
            Tix Prism <br />
          </Title>
          <Title level={5} className="titleFont sub" style={{ marginTop: 0 }}>
            Management
          </Title>
        </div>
      </div>
      <Outlet />
    </div>
  );
};
export default Entrance;
