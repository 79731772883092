import { useState, useEffect } from 'react'
// import useLoginSocial from '../pages/LoginSocial/useLoginSocial'
import { useLocation, useNavigate } from 'react-router-dom'
export default function () {
    const [tenantFromLS, setTenantFromLS] = useState({})
    // const [tenantKey, setTenantKey] = useState('')
    // const [tenantName, setTenantName] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const getTenant = async () => {
        console.log('getTenant');
        const tenant = JSON.parse(localStorage.getItem('tenant'))
        const user = localStorage.getItem('user_prism')
        // console.log({ tenant });
        // console.log({ user });
        if (tenant && user) {
            // setTenantKey(tenant.key)
            // setTenantName(tenant.display_name)
            await setTenantFromLS(tenant)
            console.log({ location })
            if (!location.pathname.includes(`/acts/${tenant.key}`)) {
                navigate(`/acts/${tenant.key}`)
            }
            return tenant
        }
        else if (user) {
            await navigate('/tenants')
            // window.location.replace('/tenants')
        } else {
            await navigate('/entrance')
        }
    }
    // useEffect(() => {
    //     if (init) getTenant()
    // }, [])
    return {
        tenantFromLS,
        // tenantKey,
        // tenantName,
        getTenant,
    }
}