import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button, Space, Table, Tag } from "antd";
import { EditFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";

import { acts, fetchActs } from "./actsSlice";
import useBread from "../../components/breadcrumbBar/useBread";
import useDateFormat from "../../hooks/useDateFormat";
import useGetTenant from "../../hooks/useGetTenant";
import CreateModal from "../../components/createModal";
import "./home.less";

const { Text, Title } = Typography;

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editMode, setEditMode] = useState({ status: false, id: null });
  const { getTenant, tenantFromLS } = useGetTenant();

  const actsList = useSelector(acts);
  const formatDate = useDateFormat();
  const dispatch = useDispatch();
  useBread();

  useEffect(() => {
    getTenant();
    dispatch(fetchActs());
  }, []);

  const handleShowModal = (props) => {
    console.log({ props });
    if (props.id) setEditMode({ status: true, id: props.id });
    else setEditMode({ status: false, id: null });
    setIsModalVisible(true);
  };

  const actHeaders = [
    {
      title: "活動名稱",
      width: 300,
      dataIndex: "title",
    },
    {
      title: "活動期間",
      sorter: true,
      width: 400,
      // sorter: (a, b) => a.date - b.date,
      render: (act) => {
        if (!act.start_at || !act.end_at) return "-";
        return `${formatDate(act.start_at)} - ${formatDate(act.end_at)}`;
      },
    },
    {
      title: "建立時間",
      dataIndex: "created_at",
      sorter: true,
      width: 350,
      // sorter: (a, b) => a.date - b.date,
      render: (val) => formatDate(val),
    },
    {
      title: "活動狀態",
      dataIndex: "is_active",
      width: 200,
      render: (actStatus) =>
        actStatus ? (
          <Tag className="actOn">開啟</Tag>
        ) : (
          <Tag className="actOff">關閉</Tag>
        ),
    },
    {
      title: "操作",
      key: "action",
      render: (props) => (
        <Space size="large">
          <a
            onClick={() => {
              handleShowModal(props);
            }}
            style={{ marginRight: "16px" }}
          >
            <Text style={{ fontSize: "20px" }} className="primary-text">
              <EditFilled />
            </Text>
          </a>
          <Button type="primary" shape="round">
            <Link
              to={`/acts/${tenantFromLS.key}/${props.key}`}
              className="fontWeightBold btnFontSize"
            >
              查看票券
            </Link>
          </Button>
        </Space>
      ),
    },
  ];
  const tableColumns = actHeaders.map((item) => ({ ...item, ellipsis }));

  return (
    <>
      <Title style={{ margin: "16px 0" }}>活動列表</Title>
      <CreateModal
        editMode={editMode}
        setEditMode={setEditMode}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleShowModal={handleShowModal}
      />
      <Table
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          showLessItems: true,
        }}
        columns={tableColumns}
        dataSource={actsList}
      />
    </>
  );
};
export default Home;
