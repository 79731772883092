import { createSlice } from '@reduxjs/toolkit';
const tenant = JSON.parse(localStorage.getItem('tenant')) || { key: "" }
const initialState = {
    tenantFromStore: {},
    breadData: [
        {
            key: 0,
            label: '活動列表',
            page: 'acts',
            path: ""
            // path: `/acts/${tenant.key}`
        },
        {
            key: 1,
            label: '活動票券',
            page: 'tickets',
            path: '',
        },
        {
            key: 2,
            label: '票券細節',
            page: 'users',
            path: ''
        },
    ],
    breadList: [
        {
            key: 0,
            label: '活動列表',
            page: 'acts',
            path: ""
            // path: `/acts/${tenant.key}`
        },
        // {
        //     key: 2,
        //     label: '活動票種',
        //     page: 'tickets',
        //     path: '/act/1'
        // },
        // {
        //     key: 3,
        //     label: '票券使用者清單',
        //     page: 'users',
        //     path: '/act/1/2'
        // },
    ],
};

export const breadcrumbBarSlice = createSlice({
    name: 'breadcrumbBar',
    initialState,
    reducers: {
        setTenant: (state, action) => {
            state.tenantFromStore = action.payload
        },
        addBreadItem: (state, action) => {
            state.breadList.push(action.payload)
        },
        cutBreadItem: (state, action) => {
            state.breadList = state.breadList.slice(0, action.payload)
        },
        setBreadItem: (state, action) => {
            state.breadList = action.payload
            // console.log(action.payload);
        },
    },

});

export const { addBreadItem, cutBreadItem, setBreadItem, setTenant } = breadcrumbBarSlice.actions;

export const selectorTenant = (state) => state.breadcrumbBar.tenantFromStore;
export const selectorbreadList = (state) => state.breadcrumbBar.breadList;
export const selectorbreadData = (state) => state.breadcrumbBar.breadData;

export default breadcrumbBarSlice.reducer;
