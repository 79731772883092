import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiGetTicketOwener } from "../../api/tickets";

const host_url = process.env.REACT_APP_HOST_URL;

//actions
// TODO: tenant variable
export const fetchOwners = createAsyncThunk(
  "users/fetchOwners",
  async ({ actId, tkId,username }) => {
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    let res = await apiGetTicketOwener(tenant.key, actId, tkId,{username})
    console.log(res);
    return res.data;
  }
);

const usersSlice = createSlice({
  name: "uesrs",
  initialState: {
    owners: []
  },
  reducers: {

  },
  extraReducers: {
    [fetchOwners.fulfilled]: (state, actions) => {
      console.log('succeeded', actions.payload.results);
      state.owners = actions.payload.results.map(el => {
        el.key = el.id
        return el
      })
    },
    [fetchOwners.rejected]: (state, actions) => {
      console.log('failed', actions.error.message);
    },
  },
});

//getter
export const owners = (state) => state.users.owners;

//setter
// export const { setModalMsg } = usersSlice.actions;
export default usersSlice.reducer;
