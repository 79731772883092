import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        signature: "aaa",
        isLogin: false,
        // showLoading: false,
        // accessToken: '',
    },
    reducers: {
        setSignature: (state, action) => {
            state.signature = action.payload;
        },
        setIsLogin: (state, action) => {
            state.isLogin = action.payload;
        },
        // setShowLoading: (state, action) => {
        //     state.showLoading = action.payload
        // },
        // setAccessToken: (state, action) => {
        //     state.showLoading = action.payload
        // }
    },
});

export const isLoginSelector = (state) => state.auth.isLogin

export const {
    setSignature,
    setIsLogin,
    // setShowLoading,
    // setAccessToken
} = authSlice.actions;
export default authSlice.reducer;
