import axios from "axios";
import useCookies from '../pages/Login/auth/useCookies';

let store
export const injectStore = _store => {
  store = _store
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_HOST_URL,
  withCredentials: true,
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFToken"
});
instance.interceptors.request.use(
  (config) => {
    // Cookies
    // const { getCookie } = useCookies()
    // const ck = getCookie('NFTtickets_login')
    // const path = window.location.pathname
    // // console.log({ ck }, path);
    // if (!ck) {
    //   console.log('no cookie');
    //   if (path != '/login') {
    //     window.location.replace('/login')
    //   }
    // }
    return config;
  },
  (err) => {
    console.log('error');
    return Promise.reject(err);
  }
);
instance.interceptors.response.use(
  (res) => {

    return res;
  },
  async (err) => {
    console.log("err");
    if (err.response.status == 401) {
      localStorage.removeItem('user_prism')
      localStorage.removeItem('tenant')
      // window.location.replace('/entrance')
    }
    return Promise.reject(err)
  }
);

export default function (method, url, data = null, config) {
  method = method.toLowerCase();
  switch (method) {
    case "get":
      return instance.get(url, { params: data });
    case "post":
      return instance.post(url, data, config);
    case "patch":
      return instance.patch(url, data);
    case "delete":
      return instance.delete(url, { params: data });
    case "patch":
      return instance.patch(url, data);
    default:
      console.log(`未知的method : ${method}`);
      return false;
  }
}
