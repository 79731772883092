import WalletConnect from "@walletconnect/web3-provider";
// import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const providerOptions = {
    //   walletlink: {
    //     package: CoinbaseWalletSDK, // Required
    //     options: {
    //       appName: "Web 3 Modal Demo", // Required
    //       infuraId: process.env.INFURA_KEY, // Required unless you provide a JSON RPC url; see `rpc` below
    //     },
    //   },
    walletconnect: {
        package: WalletConnect, // required
        options: {
            //   infuraId: "f258a7f186144f3eaa4c56e93d6634cd", // required
            rpc: {
                1: "https://arb1.arbitrum.io/rpc",
            },
        },
    },
};
