// import { useState } from "react";
import { Card, Button, Space, Switch } from "antd";
import { useTheme } from "../styles/useTheme";
// import { ConfigProvider } from 'antd'
const About = () => {
    const [darkMode, setDarkMode] = useTheme()
    return (
        <>
            <Card>
                <Space>
                    黑暗模式
                    <Switch
                        checked={darkMode}
                        onChange={setDarkMode}
                    />
                </Space>
            </Card>
        </>
    )
}
export default About