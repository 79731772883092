import { Typography, Space, Input, DatePicker, Divider, Row, Col } from "antd";
import { EditFilled, CalendarOutlined } from "@ant-design/icons";
import useDateFormat from "../../hooks/useDateFormat";

const { Text } = Typography;

const Ticket = (props) => {
  const { tkData, handleTicketSet } = props;
  const formatDate = useDateFormat();

  return (
    <Space
      direction="vertical"
      className={["ticket", tkData.is_active ? "" : "ticket_not_act"]}
    >
      <Row justify="space-between">
        <Col>
          <Text
            strong
            style={{ fontSize: "18px" }}
            type={!tkData.is_active && "secondary"}
          >
            {tkData.title}
          </Text>
        </Col>
        <Col>
          <a onClick={() => handleTicketSet(tkData)}>
            <Text type="secondary">
              <EditFilled />
            </Text>
          </a>
        </Col>
      </Row>
      <div>
        <CalendarOutlined />
        <Divider type="vertical"></Divider>
        {formatDate(tkData.start_at)} - {formatDate(tkData.end_at)}
      </div>
      {/* 230217-隱藏 */}
      {/* <div>
          <i className="fa-solid fa-ticket"></i>
          <Divider type="vertical"></Divider>
          {tkData.limit==0? "無限" : tkData.limit } 張
      </div> */}
    </Space>
  );
};
export default Ticket;
