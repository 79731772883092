import { useNavigate } from 'react-router-dom'
import { apiLogout } from '../../api/auth'

const useLoginSocial = () => {
  const navigate = useNavigate()

  const handleLogout = async () => {
    // disconnect()
    try {
      let res = await apiLogout()
      console.log(res)
      localStorage.removeItem('user_prism')
      localStorage.removeItem('tenant')
      navigate('/entrance')
    } catch (err) {
      console.log(err)
    }
  }
  return {
    handleLogout
  }
}
export default useLoginSocial