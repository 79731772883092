import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    collapsed: false,
    title: 'Activities'
};

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleSidebar: (state, action) => {
            state.collapsed = action.payload
        },
        setTitle: (state, action) => {
            state.title = action.payload
        },
    },

});

export const { toggleSidebar, setTitle } = sidebarSlice.actions;


export const selecterCollapsed = (state) => state.sidebar.collapsed;
export const selecterTitle = (state) => state.sidebar.title;


export default sidebarSlice.reducer;
