import { useState, useEffect } from "react";
import { Typography, Table, Input, Space, Tag, Popconfirm, Button } from "antd";
import moment from "moment";
import useBread from "../../components/breadcrumbBar/useBread";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOwners, owners } from "./uersSlice";
import { apiUpdateTicketStatus } from "../../api/tickets.js";
import useDateFormat from "../../hooks/useDateFormat";
import { EditOutlined } from "@ant-design/icons";
import "./users.less";

const { Text, Title } = Typography;
const { Search } = Input;

const data = [];

const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD hh:mm");
};

for (let i = 1; i <= 100; i++) {
  data.push({
    key: i,
    userId: `user${i}`,
    ticketStatus: "可使用",
    exchanged_date: formatDate(new Date()),
    end_at: formatDate(new Date()),
    used_date: formatDate(new Date()),
  });
}
/* ticket status TODO:
    IS_NEW = 1
    # IS_ALLOCATED = 10
    IS_SENT = 20
    IS_RECEIVED = 30
    IS_USED = 40
     */

const Users = () => {
  useBread();
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const ownerList = useSelector(owners);
  const formatDate = useDateFormat();

  // Search
  // const [searchText, setSearchText] = useState(null);
  const onSearch = async (val) => {
    console.log("onSearch", val);
    let query = params;
    if (val) {
      query = { ...params, username: val };
    }
    await dispatch(fetchOwners(query));
  };
  useEffect(() => {
    dispatch(fetchOwners(params));
  }, []);
  //更改票券狀態
  const [toggleTkStatusText, setToggleTkStatusText] = useState("");
  const [targetInfo, setTargetInfo] = useState("");
  const [tenant] = useState(() => JSON.parse(localStorage.getItem("tenant")));
  const handleShowEditModal = (item) => {
    console.log({ item });
    let to = {};
    if (item.status == "1") {
      to = { val: "40", text: "已使用" };
    } else if (item.status == "40") {
      to = { val: "1", text: "未使用" };
    }
    setToggleTkStatusText(`你是否確定要將此票券狀態改為${to} ？`);
    setTargetInfo({ id: item.id, status: to.val });
  };
  const confirm = async () => {
    console.log("confirm", targetInfo);
    console.log({ params });
    try {
      let payload = {
        status: targetInfo.status,
      };
      let res = await apiUpdateTicketStatus(
        payload,
        params.tenant,
        params.actId,
        params.tkId,
        targetInfo.id
      );
      console.log({ res });
      await dispatch(fetchOwners(params));
    } catch (err) {
      console.log({ err });
    }
  };
  //Table Headers
  const columns = [
    {
      title: "使用者 Address",
      dataIndex: "owner_address",
      width: 550,
    },
    {
      title: "票券狀態",
      dataIndex: "status",
      width: 300,
      render: (status, item) => {
        let tkStatus = {};
        if (status == 1) {
          tkStatus = {
            text: "未使用",
            class: "actOff",
          };
        } else if (status == 40) {
          tkStatus = {
            text: "已使用",
            class: "actOn",
          };
        }
        return (
          <>
            <Tag className={tkStatus.class}>{tkStatus.text}</Tag>
            <Popconfirm
              placement="right"
              title={() => {
                let to = "";
                if (item.status == "1") to = "已使用";
                else if (item.status == "40") to = "未使用";
                return (
                  <p>
                    是否確定要將此票券狀態
                    <br />
                    改為『 <Text type="danger">{to}</Text> 』?
                  </p>
                );
              }}
              onConfirm={confirm}
              okText="更改狀態"
              cancelText="取消"
            >
              <a onClick={() => handleShowEditModal(item)}>
                <Text style={{ fontSize: "20px" }} className="info-text">
                  <EditOutlined />
                </Text>
              </a>
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: "領取時間",
      dataIndex: "updated_at",
      width: 300,
      render: (updated_at) => {
        if (!updated_at) return "-";
        return `${formatDate(updated_at)}`;
      },
      // sorter: true,
      // sorter: (a, b) => a.date - b.date,
    },
    {
      title: "使用時間",
      dataIndex: "redeemed_at",
      render: (redeemed_at) => {
        if (!redeemed_at) return "-";
        return `${formatDate(redeemed_at)}`;
      },
      // sorter: true,
      // sorter: (a, b) => a.date - b.date,
    },
  ];
  const tableColumns = columns.map((item) => ({ ...item, ellipsis }));

  return (
    <>
      <Title level={2} style={{ margin: "16px 0" }}>
        票券細節
      </Title>
      <div className="text-right" style={{ marginBottom: "16px" }}>
        <Search
          placeholder="搜尋使用者Address"
          className="searchBar"
          onSearch={(e) => onSearch(e)}
          enterButton
          style={{
            width: 304,
          }}
        />
      </div>
      <Table
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          pageSize: 10,
          showLessItems: true,
        }}
        columns={tableColumns}
        dataSource={ownerList}
      />
    </>
  );
};
export default Users;
