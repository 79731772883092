export default function useCookies() {
    const setCookie = ({ cname = "", cvalue = "", exHours = null }) => {
        let d = new Date();
        d.setTime(d.getTime() + exHours * 60 * 60 * 1000);
        let expires = `expires=${d.toGMTString()}`;
        document.cookie = `${cname}=${cvalue};${expires}}`;
    }
    const getCookie = (cname) => {
        // console.log('getCookie');
        let name = `${cname}=`;
        let ca = document.cookie.split(";");
        let value = "";
        ca.forEach((el) => {
            el = el.trim();
            if (el.indexOf(name) == 0) {
                value = el.substring(name.length, el.length);
            }
        });
        return value
    }
    return {
        setCookie,
        getCookie
    }
}