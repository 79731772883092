import { Button, Typography, Form, Input, Space, Alert } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLayoutEffect, useState } from "react";
import "./loginSocial.less";

import { apiEmailLogin } from "../../api/auth";

const { Title, Text, Link } = Typography;
const LoginSocial = () => {
  const [errMsg, setErrMsg] = useState();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = Form.useForm();

  const reset = () => {
    form.setFieldsValue({
      username: "",
      password: "",
    });
  };
  // Login Submit
  const onSubmit = async () => {
    console.log("submit success", form.getFieldsValue());
    let payload = await form.getFieldsValue();
    try {
      let res = await apiEmailLogin(payload);
      console.log({ res });
      if (res.data.user) {
        localStorage.setItem("user_prism", JSON.stringify(res.data.user));
        navigate("/tenants");
      }
    } catch (err) {
      console.log({ err });
      let str = Object.values(err.response.data).join("\n");
      setErrMsg(str);
      reset();
    }
  };
  // For Test
  const onFill = () => {
    form.setFieldsValue({
      username: "reckie@lychee.tw",
      password: "test1234",
    });
  };
  useLayoutEffect(() => {
    if (searchParams.get("admin") == 1) {
      onFill();
    }
  }, []);

  return (
    <div className="wrapper">
      <Space direction="vertical">
        <Title level={2} className="titleFont2">
          Login <br />
        </Title>
        <Form form={form} onFinish={onSubmit}>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input placeholder="Username" autoComplete="off" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Password" autoComplete="off" />
          </Form.Item>
          {errMsg && (
            <Form.Item>
              <Alert
                className="errMsg"
                message="Error"
                description={errMsg}
                type="error"
                showIcon
              />
            </Form.Item>
          )}

          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              className="submitBtn"
              block
              shape="round"
            >
              登入
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};
export default LoginSocial;

// Hints
{
  /* <div className="hint">
  <Text>
    Don't have an account?
    <Link style={{ marginLeft: "8px" }}>Sign up</Link>
  </Text>
</div> */
}
