import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Space,
  Table,
  Tag,
  Layout,
  Card,
  Row,
  Col,
  Modal,
} from "antd";

import { apiGetTenants } from "../../api/tenants";

import "./home.less";
import CusCard from "./CusCard";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import useLoginSocial from "../LoginSocial/useLoginSocial";
import { useDispatch, useSelector } from "react-redux";
import {
  setTenant,
  selectorTenant,
} from "../../components/breadcrumbBar/breadcrumbBarSlice";

const { Text, Title } = Typography;
const { Header, Content } = Layout;

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const navigate = useNavigate();
  const { handleLogout } = useLoginSocial();
  const dispatch = useDispatch();
  const tenantFromStore = useSelector(selectorTenant);

  const getTenants = async () => {
    try {
      let res = await apiGetTenants();
      console.log(res);
      let data = res.data;
      if (data.length == 0) {
        Modal.confirm({
          icon: <ExclamationCircleOutlined />,
          content: "您的帳號並無後台管理功能",
          okText: "我知道了",
          className: "noTenantConfirm",
        });
        handleLogout();
      }
      setTenants(data);
      // setTenants([...data, ...data]);
    } catch (err) {
      console.log(err);
    }
  };
  const choseTenant = (tenant) => {
    localStorage.setItem("tenant", JSON.stringify(tenant));
    navigate(`/acts/${tenant.key}`);
    dispatch(setTenant(tenant));
  };
  useEffect(() => {
    getTenants();
  }, []);
  useEffect(() => {
    console.log({ tenantFromStore });
  }, [tenantFromStore]);
  return (
    <>
      <Content className="container">
        <Title style={{ margin: "16px 0" }}>頻道</Title>
        <Space size="large">
          {tenants.map((el, idx) => (
            <div
              key={el.id}
              onClick={() => {
                choseTenant(el);
              }}
            >
              <CusCard idx={idx} name={el.display_name}></CusCard>
            </div>
          ))}
        </Space>
      </Content>
    </>
  );
};
export default Tenants;

{
  /* <Header>
  <Row justify="space-between">
    <Col>
      <img src="/prism.png" alt="" className="logoImg logoImg_sm" /> NFT
      Prism
    </Col>
    <Col>
      <Button onClick={handleLogout} ghost>
        登出
      </Button>
    </Col>
  </Row>
</Header> */
}
