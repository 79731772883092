import { useSelector, useDispatch } from "react-redux";
import { selecterCollapsed, setTitle } from "./sidebarSlice";
import { Layout, Menu, Typography, Divider } from "antd";
import {
  UserOutlined,
  AppstoreOutlined,
  VideoCameraOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./sidebar.css";

const { Sider } = Layout;
const { Title } = Typography;

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const collapsed = useSelector(selecterCollapsed);

  const items = [
    {
      key: "1",
      icon: <AppstoreOutlined style={{ fontSize: "20px" }} />,
      label: "活動列表",
      path: "/acts",
    },
    // {
    //   key: "2",
    //   icon: <UserOutlined style={{ fontSize: "20px" }} />,
    //   label: "Account",
    //   path: "/account",
    // },
    // {
    //   key: "3",
    //   icon: <SettingOutlined style={{ fontSize: "20px" }} />,
    //   label: "Setting",
    //   path: "/about",
    // },
  ];

  const handleMenuChange = (e) => {
    let item = items.find((el) => el.key == e.key);
    dispatch(setTitle(item.label));
    navigate(item.path);
  };

  return (
    <>
      <Sider trigger={true} collapsible collapsed={collapsed}>
        <Title
          level={3}
          className="titleFont light"
          style={{ textAlign: "center", padding: "18px 9px 8px", margin: 0 }}
        >
          <img src="/prism.png" alt="" className="logoImg logoImg_sm" /> NFT
          Prism
        </Title>
        <Divider style={{ borderColor: "#fff4", margin: "0 0 16px 0" }} />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={handleMenuChange}
          items={items}
        />
      </Sider>
    </>
  );
};

export default Sidebar;
