import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import { apiGetActs } from '../../api/acts'

const host_url = process.env.REACT_APP_HOST_URL;

//actions
// TODO: tenant variable
export const fetchActs = createAsyncThunk(
  "acts/fetchActs",
  async () => {
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    let res = await apiGetActs(tenant.key);
    console.log(res);
    return res.data;
  }
);

const actsSlice = createSlice({
  name: "activities",
  initialState: {
    acts: []
  },
  reducers: {

  },
  extraReducers: {
    [fetchActs.fulfilled]: (state, actions) => {
      // console.log('succeeded');
      state.acts = actions.payload.map(el => {
        el.key = el.id
        return el
      })
    },
    [fetchActs.rejected]: (state, actions) => {
      console.log('failed', actions.error.message);
    },
    // [takeTicket.fulfilled]: (state, actions) => {
    //   let modal = {};
    //   modal.payload = {
    //     status: true,
    //     type: "rgstSuccess",
    //     title: "報名成功",
    //     content: "活動報名成功！可前往票夾查看活動及票券。",
    //   };
    //   // evnetSlice.caseReducers.setModalMsg(state, modal);
    // },
  },
});

//getter
export const acts = (state) => state.activities.acts;

//setter
// export const { setModalMsg } = actsSlice.actions;
export default actsSlice.reducer;
