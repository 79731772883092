import Web3Modal from 'web3modal'
import { ethers } from "ethers";
import { providerOptions } from "./providerOptions";
import CustomProvider from "./auth-provider"

import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import useCookies from "./useCookies"

import { setIsLogin } from "./authSlice"
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions, // required
});

const useCheckLogin = () => {
    const [provider, setProvider] = useState();
    const [library, setLibrary] = useState();
    const [account, setAccount] = useState();
    const [error, setError] = useState("");
    const [chainId, setChainId] = useState();
    const [network, setNetwork] = useState();
    const [loginCookie, setLoginCookie] = useState('')

    const isLogin = useSelector(state => state.auth.isLogin)
    const dispatch = useDispatch()
    const { setCookie, getCookie } = useCookies()
    const cookieIsMounted = useRef(false)
    const accountIsMounted = useRef(false)
    const navigate = useNavigate()

    const info = (msg) => {
        Modal.info(msg);
    };


    // -- login --
    const connectWallet = async () => {
        try {
            console.log('connect');
            const provider = await web3Modal.connect();
            // console.log({ provider });
            const library = new ethers.providers.Web3Provider(provider);
            // console.log({ library });
            const accounts = await library.listAccounts();
            // console.log({ accounts });
            const network = await library.getNetwork();
            setProvider(provider);
            setLibrary(library);
            if (accounts) setAccount(accounts[0]);
            setChainId(network.chainId);
        } catch (err) {
            setError(err);
            info({
                title: '發生錯誤',
                icon: <ExclamationCircleOutlined />,
                content: `${err.message} 登入失敗`,
                okText: 'ok',
            })
        }
    };

    const signMessage = async (message) => {
        if (!library) return;
        try {
            const signature = await library.provider.request({
                method: "personal_sign",
                params: [message, account],
            });
            return signature;
        } catch (err) {
            setError(err);
            info({
                title: '發生錯誤',
                icon: <ExclamationCircleOutlined />,
                content: `${err.message} 登入失敗`,
                okText: 'ok',
            })
        }
    };

    const login = async () => {
        try {
            const customProvider = new CustomProvider();
            const message = await customProvider.getNonceMsg(account);
            console.log({ message });
            const signature = await signMessage(message);
            console.log({ signature });

            const resp = await customProvider.login(account, signature);
            console.log(resp);
            setCookie({ cname: "NFTtickets_login", cvalue: "1", exHours: 4 })
            navigate('/acts')
            await dispatch(setIsLogin(true))
        } catch (err) {
            console.log(err);
            info({
                title: '發生錯誤',
                icon: <ExclamationCircleOutlined />,
                content: `${err.message} 登入失敗`,
                okText: 'ok',
            })
        }
    };

    // -- logout --
    const logout = async () => {
        const customProvider = new CustomProvider();
        await customProvider.logout();
        dispatch(setIsLogin(false));
    };

    const refreshState = () => {
        setAccount();
        setChainId();
        setNetwork("");
        setCookie({ cname: "NFTtickets_login", cvalue: "0", exHours: 0 })
    };

    const disconnect = async () => {
        console.log('disconnect');
        await logout()
        await web3Modal.clearCachedProvider();
        refreshState();
    };

    const init = async () => {
        console.log('init');
        let ck = getCookie('NFTtickets_login')
        console.log({ ck });
        if (ck) {
            await dispatch(setIsLogin(true))
        }
    }

    useEffect(() => {
        if (accountIsMounted.current) {
            console.log('account changed:', account);
            if (!loginCookie && account) {
                login()
            }
        } else {
            accountIsMounted.current = true
        }
    }, [account]);

    // -- watch provider --
    useEffect(() => {
        if (accountIsMounted.current) {
            if (provider?.on) {
                const handleAccountsChanged = (accounts) => {
                    console.log("accountsChanged", accounts);
                    if (accounts) setAccount(accounts[0]);
                };

                const handleChainChanged = (_hexChainId) => {
                    setChainId(_hexChainId);
                };

                const handleDisconnect = () => {
                    console.log("disconnect", error);
                    disconnect();
                };

                provider.on("accountsChanged", handleAccountsChanged);
                provider.on("chainChanged", handleChainChanged);
                provider.on("disconnect", handleDisconnect);

                return () => {
                    if (provider.removeListener) {
                        provider.removeListener("accountsChanged", handleAccountsChanged);
                        provider.removeListener("chainChanged", handleChainChanged);
                        provider.removeListener("disconnect", handleDisconnect);
                    }
                };
            }
        }
    }, [provider]);
    return {
        connectWallet,
        disconnect,
        init,
        refreshState,
    }

}
export default useCheckLogin