import React from 'react';
import axios from "axios";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './styles/themeProvider';
import App from './App';
import reportWebVitals from './tests/reportWebVitals';
import useCookies from './pages/Login/auth/useCookies';
// import './styles/index.less';
import './styles/main.less'

axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

axios.interceptors.request.use((config) => {
  // const { getCookie } = useCookies()
  // const ck = getCookie('NFTtickets_login')
  const user = localStorage.getItem('user_prism')
  const path = window.location.pathname
  // console.log({ ck }, path);
  // if (!ck) {
  if (!user) {
    // console.log('no cookie');
    if (path != '/entrance') {
      window.location.replace('/entrance')
    }
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log('error');
  return Promise.reject(error);
});


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
