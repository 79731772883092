import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { useState, useEffect } from "react";
import "./createModal.less";
const ImgUpload = ({ actEdit, fileList, setFileList }) => {
  const [thunbUrl, setthunbUrl] = useState("");
  const onChange = ({ fileList: newFileList }) => {
    console.log({ newFileList });
    setFileList(newFileList);
  };
  // const onChange = (e) => {
  //   console.log(e);
  //   // console.log({ newFileList });
  //   // setFileList(newFileList);
  // };
  const onPreview = async (file) => {
    console.log({ file });
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
  const beforeUpload = (file) => {
    console.log({ file });
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFileList(() => [{ originFileObj: file, uid: file.uid }]);
    };

    return false;
  };
  useEffect(() => {
    console.log({ fileList });
  }, [fileList]);
  return (
    // ImpCrop -> 圖片裁切
    // <ImgCrop rotate aspect={1.33333} shape="rect">
    <Upload
      beforeUpload={beforeUpload}
      listType="picture-card"
      fileList={fileList}
      onChange={onChange}
      onPreview={onPreview}
      className="previewWrapper"
      // disabled={!actEdit}
    >
      {fileList.length < 1 && "+ Upload"}
    </Upload>
    // </ImgCrop>
  );
};
export default ImgUpload;
