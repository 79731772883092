import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { selectorbreadList } from "./breadcrumbBarSlice";
import { Link } from "react-router-dom";

const BreadcrumbBar = () => {
  const breadList = useSelector(selectorbreadList);
  return (
    <Breadcrumb>
      {breadList.map((item, idx, arr) => {
        if (idx == arr.length - 1)
          return <Breadcrumb.Item key={item.key}>{item.label}</Breadcrumb.Item>;
        else
          return (
            <Breadcrumb.Item key={item.key}>
              <Link to={item.path}>{item.label}</Link>
            </Breadcrumb.Item>
          );
      })}
    </Breadcrumb>
  );
};

export default BreadcrumbBar;
