import { useSelector, useDispatch } from 'react-redux';

import Router from './routes';
import Sidebar from './components/sidebar';
import { toggleSidebar, selecterCollapsed, selecterTitle } from './components/sidebar/sidebarSlice';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { Button, Col, Layout, Row, Typography, Space } from 'antd';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useCheckLogin from './pages/Login/auth/useCheckLogin';
import useLoginSocial from './pages/LoginSocial/useLoginSocial';

const { Header, Content } = Layout;
const { Title } = Typography;

function App() {
  const dispatch = useDispatch()
  const collapsed = useSelector(selecterCollapsed)
  const title = useSelector(selecterTitle)
  const path = useLocation()
  const { disconnect } = useCheckLogin()
  const { handleLogout } = useLoginSocial()
  const navigate = useNavigate()


  const withoutLayoutList = [
    "/entrance",
    "/login",
  ]
  return (
    <Layout className="App">
      {withoutLayoutList.includes(path.pathname) ?
        <Router />
        : (<>
          {/* 有需要再開啟 */}
          {/* <Sidebar /> */}
          <Layout className="site-layout">
            {/* 有需要再開啟 */}
            {/* <Header
              className="site-layout-background"
              style={{
                padding: '0px 16px',
              }}
            >
              <Row justify="space-between">
                <Col onClick={() => dispatch(toggleSidebar(!collapsed))}>
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Col>
                <Col>
                  <Button onClick={handleLogout} ghost>登出</Button>
                </Col>
              </Row>
            </Header> */}
            <Header style={{ padding: '0 16px' }}>
              <Row justify="space-between">
                <Col>
                  <Space>
                    <img src="/prism.png" alt="" className="logoImg logoImg_sm" />
                    <Title level={4} style={{ color: '#fff', margin: '0' }}>Tix Prism</Title>
                  </Space>
                </Col>
                <Col>
                  <Button onClick={() => navigate('/tenants')}
                    className="changeChannelBtn" type="link">
                    切換頻道
                  </Button>
                  <Button onClick={handleLogout} ghost>
                    登出
                  </Button>
                </Col>
              </Row>
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: '24px 16px',
                padding: 24,
                minHeight: 280,
              }}
            >
              <Router></Router>
            </Content>
          </Layout>
        </>)
      }
      {/* For Test */}
      {/* <Router /> */}
    </Layout >
  );
}

export default App;

{/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header> */}