import { Typography, Button, Space, Table, Tag, Card, Image } from "antd";

import "./home.less";

const { Text, Title } = Typography;
const { Meta } = Card;

const CusCard = ({ name, children, idx }) => {
  return (
    <>
      <Card className={`cusCard ${!name && "noTenant"}`}>
        {name ? (
          <Meta
            avatar={<div className={`circle circle${idx}`}></div>}
            title={name}
          />
        ) : (
          children
        )}
      </Card>
    </>
  );
};
export default CusCard;
