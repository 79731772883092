import { useState, useEffect } from "react";
import {
  Typography,
  Space,
  Modal,
  Form,
  Input,
  DatePicker,
  Radio,
  InputNumber,
  Switch,
} from "antd";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchTickets } from "../../pages/Tickets/ticketsSlice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { apiEditTicket, apiCreateTicket } from "../../api/tickets";

const host_url = process.env.REACT_APP_HOST_URL;

const { Text } = Typography;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const TicketEditor = (props) => {
  const {
    ticketModal,
    setTicketModal,
    actId,
    ticketEdit,
    tkActiveText,
    handleTkActive,
  } = props;
  const [limitRadioValue, setLimitRadioValue] = useState(1);
  const [limitInputValue, setLimitInputValue] = useState(1);
  const [limitValue, setLimitValue] = useState(0);
  const [tenant] = useState(() => JSON.parse(localStorage.getItem("tenant")));

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ticketEdit.status) {
      let data = ticketEdit.tkData;
      data = {
        ...data,
        actDateRange: [
          moment(data.start_at).add(8, "h"),
          moment(data.end_at).add(8, "h"),
        ],
      };
      console.log({ data });
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [ticketEdit]);

  const handleRadioChange = (e) => {
    setLimitRadioValue(e.target.value);
    if (e.target.value == 0) {
      setLimitInputValue(1);
      setLimitValue(0);
    } else if (e.target.value == 1) {
      setLimitValue(1);
    }
  };
  const handleLimitOnchange = (limit) => {
    setLimitInputValue(limit);
    setLimitValue(limit);
    console.log("change limit", limit);
  };

  const onCancel = async () => {
    setTicketModal(false);
  };
  const onFinish = async (values) => {
    const {
      title,
      desc,
      actDateRange,
      limit,
      contract_addr,
      is_active,
      take_cnt,
    } = values;
    try {
      const payload = {
        title,
        desc,
        is_active,
        take_cnt,
        limit: limitValue,
        start_at: actDateRange[0]._d,
        end_at: actDateRange[1]._d,
        contract_addr,
      };
      console.log({ payload });
      if (ticketEdit.status) {
        console.log({ tenant });
        let res = await apiEditTicket(
          payload,
          tenant.key,
          actId,
          ticketEdit.tkData.id
        );
        console.log(res);
        if (res.status == 200) {
          form.setFieldsValue();
          onCancel();
          await dispatch(fetchTickets(actId));
        }
      } else {
        let res = await apiCreateTicket(payload, tenant.key, actId);
        console.log(res);
        if (res.status == 201) {
          form.resetFields();
          onCancel();
          await dispatch(fetchTickets(actId));
        }
      }
    } catch (err) {
      console.log(err);
      Modal.info({
        title: "票券建立失敗",
        icon: <ExclamationCircleOutlined />,
        content: `${err.message} 發生錯誤，請稍候再試`,
        okText: "ok",
      });
    }
  };
  const handleOk = () => {
    form.submit();
  };
  return (
    <Modal
      title="票券編輯"
      visible={ticketModal}
      width={600}
      centered
      onCancel={onCancel}
      onOk={handleOk}
      maskClosable={false}
      forceRender
    >
      <Form
        colon={false}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 19 }}
        initialValues={{ actAmt: 1 }}
        autoComplete="off"
        onFinish={onFinish}
        form={form}
      >
        <div>
          <Form.Item
            label="票券名稱/場次"
            name="title"
            rules={[
              { required: true, message: "Please input your ticket name!" },
            ]}
          >
            <Input style={{ maxWidth: "320px" }} />
          </Form.Item>
          <Form.Item label="票券說明" name="desc">
            <TextArea rows={4} style={{ maxWidth: "320px" }} />
          </Form.Item>
          <Form.Item
            label="票券期間"
            name="actDateRange"
            rules={[
              { required: true, message: "Please input your date range!" },
            ]}
            help="需設定於活動期間內"
            style={{ marginBottom: "4px" }}
          >
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ maxWidth: "320px" }}
            />
          </Form.Item>
          {/* 230217-隱藏 */}
          {/* <Form.Item
            label="票券總數"
            name="limit"
            rules={[
              { required: true, message: "Please input your ticket limit!" },
            ]}
            initialValue={0}
          >
            <Radio.Group value={limitRadioValue} onChange={handleRadioChange}>
              <Radio value={0}>無限制</Radio>
              <Radio value={1}>
                <InputNumber
                  value={limitInputValue}
                  min={1}
                  max={9999}
                  defaultValue={1}
                  disabled={limitRadioValue == 0 ? true : false}
                  onChange={handleLimitOnchange}
                />
              </Radio>
            </Radio.Group>
          </Form.Item> */}
          <Form.Item
            label="可領取張數"
            name="take_cnt"
            rules={[
              {
                validator: (_, val) => {
                  console.log({ val });
                  if (!val)
                    return Promise.reject(new Error("可領取張數為必填"));
                  if (+val <= 0 || +val > 10)
                    return Promise.reject(
                      new Error("最多同時領取10張，最少領取1張")
                    );
                  return Promise.resolve();
                },
              },
            ]}
            initialValue="1"
            extra={<p style={{ fontSize: "11px" }}>一個NFT可以領取到的張數</p>}
          >
            <Input type="number" min="1" style={{ maxWidth: "320px" }} />
          </Form.Item>
          <Form.Item label="票券狀態" style={{ marginTop: "8px" }}>
            <Space align="baseline">
              <Form.Item name="is_active" valuePropName="checked">
                <Switch onChange={handleTkActive} />
              </Form.Item>
              <Text
                type="secondary"
                className="caption"
                style={{ marginLeft: "4px" }}
              >
                {tkActiveText ? "開啟" : "關閉"}
              </Text>
            </Space>
          </Form.Item>

          <Form.Item
            label="NFT合約地址"
            name="contract_addr"
            rules={[
              {
                required: true,
                message: "Please input your Contract Address!",
              },
            ]}
            //for TEST:
            initialValue="0x12A0CbfFE2586c2ADE92F7FF22FF58E709Ea8dF4"
            help="必須持有該地址之NFT才可以領取票卷"
          >
            <Input style={{ maxWidth: "320px" }} />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
export default TicketEditor;
