import { lazy,Suspense } from "react";
import { useTheme } from "./useTheme";

const DarkTheme = lazy(()=> import("./darkTheme"))
const LightTheme = lazy(()=> import("./lightTheme"))

export const ThemeProvider = ({ children })=>{
    const [darkMode] = useTheme()

    return(
        <>
            <Suspense fallback={<span/>}>
                { darkMode? <DarkTheme/> : <LightTheme/> }
            </Suspense>
            { children }
        </>
    )
}