import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import BreadcrumbBar from '../components/breadcrumbBar'

import Tenants from '../pages/Acts/Tenants'
import Acts from '../pages/Acts'
import About from '../pages/About'
import Tickets from '../pages/Tickets'
import Users from '../pages/Users'
import Account from '../pages/Account'
// import Login from '../pages/Login'
import LoginSocial from '../pages/LoginSocial'
import Login from '../pages/LoginSocial/Login'
import useGetTenant from '../hooks/useGetTenant'
import { useEffect, useLayoutEffect } from 'react'

const Router = () => {
    const { pathname } = useLocation()
    const { getTenant, tenantFromLS } = useGetTenant()
    const navigate = useNavigate()
    // useEffect(() => {
    //     if (Object.keys(tenantFromLS).length != 0 && !tenantFromLS.init) {
    //         navigate(`/acts/${tenantFromLS.key}`)
    //     } else {
    //         navigate("/tenants")
    //     }
    // }, [])

    useEffect(() => {
        (async function () {
            await getTenant()
        })()

    }, [])
    // useEffect(() => {
    //     if (Object.keys(tenantFromLS).length != 0) {
    //         console.log("hi");
    //         console.log({ tenantFromLS });
    //         // navigate(`/acts/${tenantFromLS.key}`)
    //     }
    // }, [tenantFromLS])

    return (
        <>
            {pathname.includes('act') && <BreadcrumbBar />}
            <Routes>
                {/* #TODO: nested routes for act */}
                <Route path="acts">
                    <Route path=':tenant'>
                        <Route index element={<Acts />} />
                        <Route path=":actId" element={<Tickets />} />
                        <Route path=":actId/:tkId" element={<Users />} />
                    </Route>

                </Route>
                <Route path='tenants' element={<Tenants />}></Route>
                <Route path="account" element={<Account />} />
                <Route path="about" element={<About />} />
                {/* <Route path="/login" element={<Login />} /> */}
                <Route path="entrance" element={<LoginSocial />} >
                    <Route index element={<Login />}></Route>
                </Route>
                {/* Redirect */}
                <Route path='*' element={<Navigate to={`/acts/${tenantFromLS.key}`} replace />}></Route>
            </Routes>
        </>
    )
}

export default Router