import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiGetTickets } from "../../api/tickets"

const host_url = process.env.REACT_APP_HOST_URL;

//actions
// TODO: tenant variable
export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async (actId) => {
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    let res = await apiGetTickets(tenant.key, actId);
    console.log(res);
    return res.data;
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    ticketList: []
  },
  reducers: {
    clearTicketList: (state) => {
      state.ticketList = []
    }
  },
  extraReducers: {
    [fetchTickets.fulfilled]: (state, actions) => {
      console.log('succeeded');
      let list = actions.payload.results
      state.ticketList = list.map(el => {
        el.key = el.id
        return el
      })
    },
    [fetchTickets.rejected]: (state, actions) => {
      console.log('failed', actions.error.message);
    },
    // [takeTicket.fulfilled]: (state, actions) => {
    //   let modal = {};
    //   modal.payload = {
    //     status: true,
    //     type: "rgstSuccess",
    //     title: "報名成功",
    //     content: "活動報名成功！可前往票夾查看活動及票券。",
    //   };
    //   // evnetSlice.caseReducers.setModalMsg(state, modal);
    // },
  },
});

//getter
export const ticketList = (state) => state.tickets.ticketList;

//setter
export const { clearTicketList } = ticketsSlice.actions;
export default ticketsSlice.reducer;
