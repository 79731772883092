import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { addBreadItem, cutBreadItem, setBreadItem, selectorbreadData, selectorbreadList } from "./breadcrumbBarSlice";
import useGetTenant from "../../hooks/useGetTenant"

export default function () {
    const params = useParams()
    const location = useLocation()
    const breadData = useSelector(selectorbreadData)
    const breadList = useSelector(selectorbreadList)
    const tenant = JSON.parse(localStorage.getItem('tenant'))
    const dispatch = useDispatch()
    useEffect(() => {
        // at witch page
        let page = 'acts'
        if (!params.actId && !params.tkId) {
            page = 'acts'
        } else if (!params.tkId) {
            page = 'tickets'
        } else if (params.actId && params.tkId) {
            page = 'users'
        }

        let idx = breadData.findIndex(el => el.page == page)
        let pageItem = breadData[idx]
        let isExistInBread = breadList.find(el => el.page == page)

        //init
        const initBread = () => {
            let newList = breadData.filter(el => el.key <= idx)
            if (page == 'tickets') {
                newList[idx] = {
                    ...pageItem,
                    path: `/acts/${tenant.key}/${params.actId}`
                }
            } else if (page == 'users') {
                newList[idx - 1] = {
                    ...breadData[idx - 1],
                    path: `/acts/${tenant.key}/${params.actId}`
                }
                newList[idx] = {
                    ...pageItem,
                    path: `/acts/${tenant.key}/${params.actId}/${params.tkId}`
                }
            }
            dispatch(setBreadItem(newList))
        }
        initBread();

        if (!isExistInBread) {
            dispatch(addBreadItem({
                ...pageItem,
                path: location.pathname
            }))
        }
        // arrange Breadcrumb
        dispatch(cutBreadItem(idx + 1))

    }, [])
}