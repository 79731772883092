import axios from "axios";

class AuthProvider { }

class CustomProvider {
    constructor() {
        this.host = process.env.REACT_APP_HOST_URL;
    }

    async getNonceMsg(address) {
        const resp = await axios.get(`${this.host}/web3/nonce?address=${address}`);
        console.log(resp.data);
        return resp.data.message;
    }

    async login(username, password) {
        return await axios.post(`${this.host}/web3/login/`, {
            username,
            password,
            //   password: `123`,
        });
    }

    async logout() {
        return await axios.post(`${this.host}/web3/logout/`);
    }
}

export default CustomProvider;
